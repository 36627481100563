import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Faq from 'components/pages/Faq'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function EnterpriseFitnessFaq() {
  useEventPageVisited('Enterprise Fitness FAQ')
  const data = useStaticQuery(graphql`
    query {
      fitness: allMdx(
        filter: { frontmatter: { draft: { ne: true }, template: { eq: "faqFitness" } } }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        nodes {
          id
          body
          frontmatter {
            title
          }
        }
      }
      all: allMdx(
        filter: {
          frontmatter: { draft: { ne: true }, template: { eq: "faq" }, fitness: { eq: true } }
        }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        nodes {
          id
          body
          frontmatter {
            fitness
            title
          }
        }
      }
    }
  `)

  const { all, fitness } = data

  return <Faq data={[...all.nodes, ...fitness.nodes]} className="FaqFitness" />
}
